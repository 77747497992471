<template>
  <div style="color: red">请跳转到浏览器打开</div>
</template>

<script>
export default {
  name: "download",
  created() {
    var u = navigator.userAgent;
    // android终端
    // var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1;
    // ios终端
    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isiOS) {
      // 跳转到IOS APP下载路径
      window.location.href = "https://apps.apple.com/cn/app/id1660059984";
    } else {
      // 跳转到AndroidAPP下载路径
      window.location.href = "http://fir.cl-pay.cn/8d1a";
    }
    // window.location.href = 'http://fir.gdkashen.net.cn/4qtl';
  },
};
</script>

<style>
</style>
