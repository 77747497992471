<template>
  <div class="container">
    <img class="img500" src="../../assets/500yem.png" alt="服务器出问题了" />
    <p class="f2">服务器出问题了,工程师抢修中!!!</p>
    <a class="link" href="javascript:history.go(-1)">
      <img src="../../assets/back.png" alt="返回" style="width:15px;height:15px;" />
      返回上页
    </a>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.container {
  margin: auto auto;
  text-align: center;
  background-color: #f8f8f8;
  width: 100%;
  .f2 {
    color: #9c9c9c;
    font-size: 25px;
  }
  .img500 {
    width: 100%;
    height: 100%;
  }
}
.link {
  width: 85px;
  line-height: 20px;
  color: #4baefd !important  ;
  font-size: 25px;
}
a:-webkit-any-link {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;
}
.container a img {
  position: relative;
}
body {
  background-color: #f8f8f8;
}
</style>